import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../../widget/context/ContextMain'
import CModal from '../../../../widget/CModal/CModal';
import BtnLoader from '../../../../widget/Loading/BtnLoader';
import { customApi, baseUrl, ImageUrl } from '../../../../widget/apis';
import LoadingPage from '../../../../widget/Loading/LoadingPage';
import ImgCrop from 'antd-img-crop';
import { Upload, message, Popconfirm } from 'antd';
import { toast } from "react-toastify";
import type { UploadProps } from 'antd';
import UploadWP from '../../../../widget/uploadWithProgress';
import InputColor from 'react-input-color';
import CFileUpload from '../../../../widget/uploadFile';

type IListOfColorFromInput = {
  'green': any,
  'red': any,
  'blue': any,
}

function ProductAddWidget(props: any) {

  const Ctx = useContext(ContextState);
  const [displayPage, setDisplayPage] = useState<any>([])
  const [visibleColor, setVisibleColor] = useState<boolean>(false)
  const [visibleColorCustom, setVisibleColorCustom] = useState<boolean>(false)
  const [selectChildForColor, setSelectChildForColor] = useState<number>(-1)
  const [listColor, setListColor] = useState<any>([])
  const [listColorCustom, setListColorCustom] = useState<any>([])
  const [displayBtn, setDisplayBtn] = useState<any>([])
  const [colorNameToAdd, setColorNameToAdd] = useState<string>("")
  const [isAddNewColor, setIsAddNewColor] = useState<boolean>(false)
  const [selectImageOrColor, setSelectImageOrColor] = useState<number>(0)
  const [selectedImages, setSelectedImages] = useState<{
    colorImg: { path: any, url: string },
  } | any>()
  const [listOfColorFromInput, setListOfColorFromInput] = useState<IListOfColorFromInput[]>([{ blue: "", green: "", red: "" }])
  const [stockInputKeeper, setStockInputKeeper] = useState({
    'stock': 0,
    'frame': 0,
  });

  // Function For Upload Handel

  const onChangeSide = async (info: any, index: any, type: any) => {
    if (info.file.status === 'uploading') {
      // message.info(`Wait to upload file ...`);
    }
    if (info.file.status === 'done') {
      const data = [...props.childList];
      for (let i in data) {
        if (i == index) {
          data[i][type] = info.file.response.data.path;
          data[i][`${type}_url`] = info.file.response.data.url;
        }
      }
      props.setChildList(data)
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onFinishAddColor = async () => {
    if (selectImageOrColor == 0) return toast("select image or color ", { type: "warning" })
    if (colorNameToAdd.length === 0) return toast("insert name for color", { type: "warning" })
    if (selectImageOrColor == 1 && !selectedImages?.colorImg?.path) return toast("upload an image ", { type: "warning" })
    if (selectImageOrColor == 2 && !listOfColorFromInput.length) return toast("select an color ", { type: "warning" })
    setDisplayPage(true)
    let postData: any = {
      'name': colorNameToAdd,
    }
    if (selectImageOrColor == 1) postData['image'] = selectedImages?.colorImg.path;
    if (selectImageOrColor == 2) postData['hexes'] = listOfColorFromInput;

    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors` }, { method: "POST", body: JSON.stringify(postData), token: true })
    setDisplayPage(false)
    if (req.status) {
      setColorNameToAdd("")
      setSelectedImages(null)
      getColors()
      toast(req.message, { type: "success" })
    }
    else {
      toast(req.message, { type: "error" })
    }
  }


  const antdUploadProps: UploadProps = {
    name: 'image',
    listType: "picture-card",
    action: `${baseUrl}${localStorage.getItem("shopId")}/products/image`,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },

    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 4,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    onRemove(file) {
      // setFileListLenght(0)
    },
  };


  const removeImageSide = (type: string, id: number) => {
    const data = [...props.childList];
    for (let i in data) {
      if (+i == id) {
        data[i][type] = null;
        data[i][`${type}_url`] = null;
      }
    }
    props.setChildList(data)
  }


  const clearData = () => {
    setVisibleColor(false)
  }

  const openModalColor = (id: any) => {
    setSelectChildForColor(id);
    setVisibleColor(true)
  }
  const openModalCustomColor = (id: any) => {
    setSelectChildForColor(id);
    setVisibleColorCustom(true)
  }

  const handelChangeStockAndFrmaeInput = (e: any, type: any) => {
    let data: any = { ...stockInputKeeper }
    data[type] = +e.target.value;
    setStockInputKeeper(data)
  }

  const handelAddStockItem = (childId: number) => {
    if (stockInputKeeper?.frame == 0) return toast("Frame value is invalid", { type: "error" })
    let data: any = [...props.stockList];
    data.push({
      'uid': Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000,
      'index': childId,
      'stock': stockInputKeeper.stock,
      'frame': stockInputKeeper.frame,
    })
    props.setStockList(data)
  }

  const handelRemoveStockItem = (uid: number) => {
    let data: any = [...props.stockList];
    for (let i in data) {
      if (data[i].uid == uid) {
        data.splice(i, 1)
      }
    }
    props.setStockList(data)
  }

  const addChild = () => {
    const data = [...props.childList];
    data.push({
      'color': null,
      'color_id': 0,
      'stock': 0,
      'price': 0,
      'discount': 0,
      'description': null,
      'suggestion_price': 0,
      'min_price': 0,
      'top': null,
      'top_url': null,
      'left': null,
      'left_url': null,
      'right': null,
      'right_url': null,
      'lens_color': null,
      'lens_type': null,
      'images': [],
      'gltf_dir': null,
      'stock_type': null
    })
    props.setChildList(data)
  }


  const removeChild = (id: any) => {
    const data = [...props.childList];
    data.splice(id, 1)
    props.setChildList(data)
  }

  const selectedColor = (color_id: any, color: string) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == selectChildForColor) {
        data[i].color_id = color_id;
        data[i].color = color;
      }
    }
    props.setChildList(data)
    setVisibleColor(false)
    setVisibleColorCustom(false)
  }

  const changeInputChild = (type: string, index: any, value: any) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        if (type === "discount") {
          if (value <= 100) data[i][type] = value;
        } else data[i][type] = value;
        if (type === "price") {
          if (props.SelectedCat == 1) {
            if (props.brandSetting?.MSPS_x) data[i].min_price = (value * props.brandSetting.MSPS_x).toFixed(2);
            if (props.brandSetting?.RSPS_x) data[i].suggestion_price = (value * props.brandSetting.RSPS_x).toFixed(2);
          } else if (props.SelectedCat == 2) {
            if (props.brandSetting?.MSPE_x) data[i].min_price = (value * props.brandSetting.MSPE_x).toFixed(2);
            if (props.brandSetting?.RSPE_x) data[i].suggestion_price = (value * props.brandSetting.RSPE_x).toFixed(2);
          }
        }
      }
    }
    props.setChildList(data)
    setVisibleColor(false)
    props.handelAddItem(type, value, 'children')
  }


  const getColors = async () => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/dropdown` }, { method: "GET", token: true })
    const reqCustom = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/dropdown?own=true` }, { method: "GET", token: true })
    setDisplayPage(false)
    if (req.status == true) {
      setListColor(req.data)
      setListColorCustom(reqCustom.data)
    }
  }

  const confirmDeleteColor = async (item: any) => {
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/colors/${item.id}` }, { method: "DELETE", token: true })
    if (req.status == true) {
      setDisplayPage(false)
      getColors()
    } else setDisplayPage(false)
  };

  useEffect(() => {
    getColors()
  }, [])


  const onChangeImage = async (response: any, name: string) => {
    let data: any = { ...selectedImages };
    data[name] = response;
    setSelectedImages(data)
  };

  const onChangeUpload = async (response: any, name: string, index: any) => {
    if (!response) return
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        if (name === "gltf_dir") data[i][name] = response
        else data[i][name].push(response.path)
      }
    }
    props.setChildList(data)
  };


  const onChangeColor = (e: any) => {
    let data: any = { ...selectedImages };
    data['hexes'] = {
      'green': e.g,
      'red': e.r,
      'blue': e.b,
    };
    setSelectedImages(data)
  }


  const onRemoveModel = async (name: string, index: any) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        data[i][name] = null
      }
    }
    props.setChildList(data)
  };

  const addNewChildToColorInput = () => {
    let lastArr: IListOfColorFromInput[] = [...listOfColorFromInput]
    lastArr.push({
      'green': "",
      'red': "",
      'blue': "",
    })
    setListOfColorFromInput([...lastArr])
  }

  const onChangeChildToColorInput = (index: any, e: any) => {
    let lastArr: IListOfColorFromInput[] = [...listOfColorFromInput]
    lastArr[index] = {
      'green': e.g,
      'red': e.r,
      'blue': e.b,
    }
    setListOfColorFromInput([...lastArr])
  }

  const deleteColorfromInput = (item: any) => {
    let lastArr = [...listOfColorFromInput]
    if (lastArr.length === 1) return
    let findIndex = lastArr.findIndex(e => item.green == e.green && item.red == e.red)
    lastArr.splice(findIndex, 1)
    setListOfColorFromInput([...lastArr])
  }

  return (

    <>
      {displayPage && <LoadingPage />}
      <h4 className=" text-glasses-text-50 mt-8"> Color , image , price and stock </h4>
      {props.childList.map((item: any, index: any) =>
        <div key={index} className="w-full flex flex-col border border-dashed border-gray-300 rounded mt-2 p-2">
          <div className=" w-full h-9 flex items-center justify-end">
            <div onClick={() => removeChild(index)} className=" w-[20px] h-[20px] rounded-full bg-gray-300 flex justify-center items-center text-white text-xs cursor-pointer mr-[10px]">x</div>
          </div>

          <div className=" w-full  mt-2 flex">
            <div className=" w-5/12 pr-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Stock</span>
                <div className=" w-full h-[40px] ">
                  <input type="number" onChange={(e: any) => handelChangeStockAndFrmaeInput(e, 'stock')} value={stockInputKeeper.stock} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-5/12 pl-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Frame Size</span>
                <div className=" w-full h-[40px] ">
                  <input type="number" onChange={(e: any) => handelChangeStockAndFrmaeInput(e, 'frame')} value={stockInputKeeper.frame} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-2/12 pl-1 flex justify-end items-center ">
              <div onClick={() => handelAddStockItem(index)} className=" w-[40px] h-[40px] bg-glasses-head-50 text-white flex justify-center items-center rounded mt-[22px] cursor-pointer">
                Save
              </div>
            </div>
          </div>

          <div className=" w-full  mt-2 flex flex-col">
            {props.stockList.map((value: any, key: number) =>
              <>
                {value.index == index &&
                  <div key={key} className='w-full h-[35px] rounded-lg border border-dashed border-glasses-text-50 flex items-center justify-between px-1 mb-1'>
                    <div className='flex items-center'>
                      <span>Stock : {value.stock}</span>
                      <span className='mx-1'>--</span>
                      <span>Frame : {value.frame}</span>
                    </div>
                    <svg onClick={() => handelRemoveStockItem(value.uid)} x="0px" y="0px" viewBox="0 0 458.5 458.5" className={` w-4 h-4 fill-glasses-text-50  cursor-pointer `}>
                      <use xlinkHref="/assets/svg/trash.svg#trash" />
                    </svg>
                  </div>
                }
              </>
            )}
          </div>
          <div className=" w-full px-1 my-2">
            <div className=" w-full flex flex-col items-center">
              <span className=" text-glasses-text-50">Stock Type</span>
              <select onChange={(e: any) => changeInputChild('stock_type', index, e.target.value)} name={'stock_type'} className=" w-full h-[40px] rounded bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                <option value={''} selected disabled hidden>select stock type</option>
                  <option value={"in_stock"}>In Stock</option>
                  <option value={"out_stock"}>Out Of Stock</option>
                  <option value={"by_stock"}>By Stock</option>
              </select>
            </div>
          </div>


          <div className=" w-full mt-5 flex flex-wrap">

            <div className=" w-6/12 px-1 mb-2 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50 ">Frame Color {item.color && <span className=" text-glasses-text-50 text-[10px] bg-gray-200 px-2 py-1 rounded-lg ml-3">{item.color}</span>}</span>
                <div className=' flex w-full items-center justify-center'>
                  <div onClick={() => openModalCustomColor(index)} className=" w-full flex-col h-[40px] text-xs  bg-white shadow rounded  mt-1 flex items-center justify-center text-gray-400 cursor-pointer ">
                    Custom
                  </div>
                  <div onClick={() => openModalColor(index)} className=" w-full flex-col h-[40px] text-xs  bg-white shadow rounded  mt-1 flex items-center justify-center text-gray-400 cursor-pointer ">
                    Standard
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 mb-2 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Price</span>
                <div className=" w-full h-[40px]  mt-1">
                  <input value={item.price} onChange={(e) => { changeInputChild('price', index, e.target.value) }} type=" number" placeholder={'Price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Discount %</span>
                <div className=" w-full h-[40px]  mt-1">
                  <input required value={item.discount} onChange={(e) => { changeInputChild('discount', index, e.target.value) }} max={100} type="number" placeholder={'Discount'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>

            <div className=" w-6/12 px-1 ">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Suggested retail price</span>
                <div className=" w-full h-[40px] mt-1">
                  <input value={item.suggestion_price} onChange={(e) => { changeInputChild('suggestion_price', index, e.target.value) }} type=" number" placeholder={'suggestion price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            <div className=" w-6/12 px-1 mt-2">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Minimum selling price</span>
                <div className=" w-full h-[40px] mt-1">
                  <input value={item.min_price} onChange={(e) => { changeInputChild('min_price', index, +e.target.value) }} type=" number" placeholder={'Minimum selling price'} className=" w-full h-full bg-white text-glasses-text-50 shadow rounded text-center focus:outline-none" />
                </div>
              </div>
            </div>
            {/* ------------------ */}
            {props?.childrenDropDown &&
              <>
                <div className=" w-6/12 px-1 mt-2">
                  <div className=" w-full flex flex-col items-center">
                    <span className=" text-glasses-text-50">Lens Color</span>
                    <select onChange={(e: any) => changeInputChild('lens_color', index, e.target.value)} name={'lens_color'} className=" w-full h-[40px] rounded bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                      <option value={''} selected disabled hidden>select lens color</option>
                      {props?.childrenDropDown?.lens_color.map((itemCat: any, index: any) =>
                        <option key={index} value={itemCat}>{itemCat}</option>
                      )}
                      <option key={-1} value={-1}>+ Add custom item</option>
                    </select>
                  </div>
                </div>

                <div className=" w-6/12 px-1 mt-2">
                  <div className=" w-full flex flex-col items-center">
                    <span className=" text-glasses-text-50">Lens Type</span>
                    <select onChange={(e: any) => changeInputChild('lens_type', index, e.target.value)} name={'lens_type'} className=" w-full h-[40px] rounded bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required>
                      <option value={''} selected disabled hidden>select lens type</option>
                      {props?.childrenDropDown?.lens_type.map((itemCat: any, index: any) =>
                        <option key={index} value={itemCat}>{itemCat}</option>
                      )}
                    </select>
                  </div>
                </div>


              </>
            }
            <div className=" w-6/12 px-1 my-2">
              <div className=" w-full flex flex-col items-center">
                <span className=" text-glasses-text-50">Color code</span>
                <input maxLength={8} onChange={(e: any) => changeInputChild('color_code', index, e.target.value)} placeholder='Max 8 character' name={'color_code'} type="text" className=" w-full h-[40px] text-center rounded bg-white mt-1 shadow px-2 focus:outline-none text-sm text-glasses-text-50" required />
              </div>
            </div>

            <div className=" w-full flex flex-col mt-5">
              <span className=" text-glasses-text-50 text-sm pl-4">Description</span>
              <textarea
                onChange={(e: any) => changeInputChild('description', index, e.target.value)}
                name={'description'} placeholder="Insert description"
                className=" w-full h-[80px] rounded-xl bg-white mt-1 shadow p-2 focus:outline-none text-sm text-glasses-text-50"
                required defaultValue={item.description}
              />
            </div>
          </div>

          <div className=" w-full  flex mt-3 items-center">
            {['top', 'right', 'left'].map((itemCover: any, indexCover: any) =>
              <div className=" w-4/12 px-1 ">
                <div className=" w-full relative overflow-hidden">
                  {item[itemCover] == null ?
                    <ImgCrop aspect={2 / 1}>
                      <Upload
                        className=" w-full flex"
                        accept=".png , .jpg , .jpeg , .pdf "
                        onChange={(e) => onChangeSide(e, index, itemCover)}
                        {...antdUploadProps}
                      >
                        {item[itemCover] == null &&
                          <div className=" w-full bg-white shadow rounded h-[100px] p-[5px] flex items-center justify-center text-gray-400 cursor-pointe">
                            <div className=" w-full h-full border border-dashed flex flex-col justify-center items-center">
                              <h3 className="text-gray-500">
                                {itemCover == 'top' && 'Front view'}
                                {itemCover == 'left' && 'Side view'}
                                {itemCover == 'right' && 'Angle view'}
                              </h3>
                              <svg x="0px" y="0px" viewBox="0 0 218.166 218.166" className={` w-6 h-6  fill-gray-500 transition-all   `}>
                                <use xlinkHref="/assets/svg/photo.svg#photo" />
                              </svg>
                            </div>
                          </div>
                        }
                      </Upload>
                    </ImgCrop>
                    :
                    <div className=" overflow-hidden relative">
                      <div onClick={() => removeImageSide(itemCover, index)} className=" w-[20px] h-[20px] top-2 left-2 bg-rose-500 rounded-full text-white flex justify-center items-center cursor-pointer absolute">x</div>
                      <img className=" rounded" src={item[`${itemCover}_url`]} alt="cover" />
                    </div>
                  }
                </div>
              </div>
            )}
          </div>

          <div className=" w-full flex flex-col mt-5 ">
            <span className=" text-glasses-text-50 text-sm pl-4 mb-2">Upload Gallery Images</span>
            <div className=' flex w-full items-center  border border-glasses-head-50 border-dashed p-3 flex-wrap'>
              <UploadWP customText='Upload Images' imageRatio={2} currentIMG={""} setResponse={(res: any) => onChangeUpload(res, "images", index)}
                url={`${localStorage.getItem("shopId")}/products/image`} fileCount={20} />
            </div>
          </div>

          <div className=" w-full flex flex-col mt-5 ">
            <span className=" text-glasses-text-50 text-sm pl-4">Upload 3D Models</span>
            <div className=' flex w-full items-center  border-dashed p-3 flex-wrap'>
              <CFileUpload
                showUploadButtonDuringUpload
                keyPayload='zip'
                listType='picture'
                customText=' Click to upload models'
                url={`${localStorage.getItem("shopId")}/products/zip`}
                fileCount={1}
                setResponse={(res: any) => onChangeUpload(res, "gltf_dir", index)}
                onRemove={() => onRemoveModel("model", index)}
              />
            </div>
          </div>

          <div className=" w-full  flex mt-3 items-center">
            {['ar_front', 'ar_right', 'ar_left'].map((itemCover: any, indexCover: any) =>
              <div key={indexCover} className=" w-4/12 px-1 ">
                <div className=" w-full relative overflow-hidden">
                  {item[itemCover] == null ?
                    <Upload
                      className=" w-full flex"
                      accept=".png , .jpg , .jpeg , .pdf "
                      onChange={(e) => onChangeSide(e, index, itemCover)}
                      {...antdUploadProps}
                    >
                      {item[itemCover] == null &&
                        <div className=" w-full bg-white shadow rounded h-[100px] p-[5px] flex items-center justify-center text-gray-400 cursor-pointe">
                          <div className=" w-full h-full border border-dashed flex flex-col justify-center items-center">
                            <h3 className="text-gray-500">
                              {itemCover == 'ar_front' && 'Front AR View'}
                              {itemCover == 'ar_left' && 'Left AR View'}
                              {itemCover == 'ar_right' && 'Right AR View'}
                            </h3>
                            <svg x="0px" y="0px" viewBox="0 0 218.166 218.166" className={` w-6 h-6  fill-gray-500 transition-all   `}>
                              <use xlinkHref="/assets/svg/photo.svg#photo" />
                            </svg>
                          </div>
                        </div>
                      }
                    </Upload>
                    :
                    <div className=" overflow-hidden relative">
                      <div onClick={() => removeImageSide(itemCover, index)} className=" w-[20px] h-[20px] top-2 left-2 bg-rose-500 rounded-full text-white flex justify-center items-center cursor-pointer absolute">x</div>
                      <img className=" w-[150px] h-[150px]  rounded" src={item[`${itemCover}_url`]} alt="cover" />
                    </div>
                  }
                </div>
              </div>
            )}
          </div>

        </div>
      )}

      <div onClick={addChild} className="w-full h-[40px] border border-dashed border-glasses-text-50 rounded mt-4 flex justify-center items-center cursor-pointer">
        <span className=" text-glasses-text-50 " >+ Add new color , image , price and stock </span>
      </div>

      <CModal onScap={clearData} visible={visibleColorCustom} setVisible={setVisibleColorCustom} title="AddColor" uId='AddColorCustom'>
        <div className=" w-full flex flex-wrap">
          <div className='  max-h-[40vh] overflow-auto flex w-full flex-wrap '>
            {listColorCustom.map((item: any, index: any) =>
              <div key={index} className=" w-6/12 p-1">
                <div className=" w-full h-[40px] border rounded flex justify-between overflow-hidden items-center px-2 cursor-pointer">
                  <div className=' flex items-center justify-between w-full ' onClick={() => selectedColor(item.id, item.name)}>
                    <span className=" text-xs">{item.name}</span>
                    <img src={`${item.image}`} className=" w-[25px] h-[25px] rounded-full" alt="color" />
                  </div>
                  <Popconfirm placement="top" title={`Are you sure to delete ${item.name} ?`} onConfirm={() => confirmDeleteColor(item)} okText="Yes" cancelText="No">
                    <svg x="0px" y="0px" viewBox="0 0 512 512" className={` w-6 h-6 ml-3 fill-glasses-head-50 transition-all cursor-pointer   `}>
                      <use xlinkHref="/assets/svg/trash.svg#trash" />
                    </svg>
                  </Popconfirm>
                </div>
              </div>
            )}
          </div>
          {visibleColorCustom &&
            <>
              <div className=' w-full flex items-center justify-between pb-2 '>
                <span className=' mt-4  '>Add New One </span>
                <div className='  mt-4 p-1 rounded-md bg-blue-100 flex justify-center items-center  drop-shadow-xl  '>
                  <span onClick={() => setIsAddNewColor(!isAddNewColor)} className=' cursor-pointer text-xs rounded-xl text-gray-700'><img className={` w-4 ${isAddNewColor ? " rotate-[90deg]" : " rotate-[-90deg]"} `} src='/assets/svg/arrow-left.svg' /></span>
                </div>
              </div>
              <div className=' w-full mb-4 border-b ' />
              <div className={` duration-700 transition-all bg-gray-100 w-full rounded-xl p-2 flex flex-col items-center justify-center 
              ${isAddNewColor ? " max-h-[480px] opacity-100 visible " : " max-h-0 opacity-0 invisible "}
              `}>
                <input value={colorNameToAdd} onChange={(e) => { setColorNameToAdd(e.target.value); }} type="text" className=" w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none" placeholder={"Insert Color Name"} required />
                <select defaultValue='' onChange={(e: any) => { setSelectImageOrColor(e.target.value) }} className=' w-full h-10 border rounded mb-3 px-2 text-glasses-text-50 focus:outline-none' required>
                  <option value='' disabled >Select image or color</option>
                  <option value={1}>Select image</option>
                  <option value={2}>Select color</option>
                </select>
                {selectImageOrColor == 1 &&
                  <div className='flex flex-col mb-[30px] items-center justify-center'>
                    <span className='mb-1 mt-2'>Select image</span>
                    <UploadWP customText='Upload Color Img' imageRatio={1} currentIMG={selectedImages?.colorImg?.url} setResponse={(res: any) => onChangeImage(res, "colorImg")}
                      url={`${localStorage.getItem("shopId")}/colors/image`} fileCount={1} />
                  </div>
                }
                {selectImageOrColor == 2 &&
                  <div className='flex flex-col mb-[30px] w-full '>
                    <span className=' mb-1 mt-2'>Select list of color</span>
                    <div className=' flex flex-wrap items-center '>
                      {listOfColorFromInput.map((item, index: number) =>
                        <div key={index} className=' flex items-center mr-3 relative mt-2 '>
                          <span className=' p-1 rounded text-xs mr-1 border '>{index + 1}</span>
                          <InputColor initialValue="#fff" onChange={(e: any) => onChangeChildToColorInput(index, e)} placement="right" />
                          {listOfColorFromInput.length !== 1 &&
                            <svg onClick={() => deleteColorfromInput(item)} x="0px" y="0px" viewBox="0 0 512 512" className={` w-4 h-4 ml-1 fill-glasses-head-50 transition-all cursor-pointer   `}>
                              <use xlinkHref="/assets/svg/trash.svg#trash" />
                            </svg>
                          }
                        </div>
                      )}
                      <div onClick={addNewChildToColorInput}
                        className=' w-[50px] h-[25px] rounded mt-2 flex justify-center items-center border border-dashed border-[#201e1e] cursor-pointer   '>
                        +
                      </div>
                    </div>
                  </div>
                }
                {displayBtn == false ?
                  <div onClick={() => onFinishAddColor()} className=" w-full h-10 bg-glasses-head-50 text-white rounded cursor-pointer flex justify-center items-center" >Add </div>
                  :
                  <div className='  flex justify-center items-center' ><BtnLoader /></div>
                }
              </div>
            </>
          }
        </div>
      </CModal>

      <CModal onScap={clearData} visible={visibleColor} setVisible={setVisibleColor} title="AddColor" uId='AddColor'>
        <div className="max-h-[40vh] overflow-auto flex w-full flex-wrap">

          {listColor.map((item: any, index: any) =>
            <div key={index} onClick={() => selectedColor(item.id, item.name)} className=" w-6/12 p-1">
              <div className=" w-full h-[40px] border rounded flex justify-between overflow-hidden items-center px-2 cursor-pointer">
                <span className=" text-xs">{item.name}</span>
                <img src={`${item.image}`} className=" w-[25px] h-[25px] rounded-full" alt="color" />
              </div>
            </div>
          )}
        </div>
      </CModal>

    </>
  );
}

export default ProductAddWidget;


